import React, { useState } from "react"
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import PostLink from "../components/post-link";
import Logos from "../components/logos";

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
}) {
    const { site, markdownRemark, allMarkdownRemark } = data; // data.markdownRemark holds your post data
    const { siteMetadata } = site;
    const { frontmatter } = markdownRemark;
    const { edges } = allMarkdownRemark

    const [tab, setTab] = useState(1)

    const expand = (value) => {
        setTab(value)
    }

    const Posts = edges
        .filter((edge) => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
        .map((edge) => <PostLink key={edge.node.id} post={edge.node} />);


    return (
        <Layout>
            <Helmet>
                <title>
                    {frontmatter.title} | {siteMetadata.title}
                </title>
                <meta name="description" content={frontmatter.metaDescription} />
            </Helmet>

            <div className="w-full mx-auto">
                <div className="py-10 sm:pt-16 lg:pt-8 lg:pb-8 lg:overflow-hidden bg-header">
                    <div className="mx-auto max-w-7xl px-4 lg:px-8">
                        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                            <div className=" sm:text-center px-4 lg:px-8 lg:text-left lg:flex lg:items-center">
                                <div className="lg:py-24">
                                    <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                                        <span className="text-base block font-semibold tracking-wide uppercase">Publishing</span>
                                        <span className="pb-3 block text-white sm:pb-5">What we publish</span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white my-10 sm:my-0">
                <div class="relative mx-auto max-w-7xl px-4 lg:px-8">
                    <div class="relative md:bg-white md:p-6">
                        <div class="lg:grid lg:grid-cols-2 lg:gap-6">
                            <div class="prose prose-indigo prose-lg text-gray-500 lg:max-w-none">
                                <p>Our services have been developed to ensure that our clients receive technically accurate and authoritative support. We offer consultancy services in all aspects of electrical installations</p>

                            </div>
                            <div class="mt-6 prose prose-indigo prose-lg text-gray-500 lg:mt-0">

                                <p>We also offer a partner package to support an organisation over a longer timeframe. Our services are not limited to the above. Please contact us and we will be happy to discuss your requirements.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="bg-white">
                <div className="max-w-7xl mx-auto pb-12 px-4 divide-y divide-gray-200 sm:px-6 lg:pb-16 lg:px-8">
                    <div className="mt-8">
                        <dl className="divide-y divide-gray-200">
                            <div className="pt-6 pb-8 md:grid md:grid-cols-12 md:gap-8">
                                <dt onClick={() => expand(1)} className="text-base font-medium text-gray-900 md:col-span-3 cursor-pointer hover:text-gray-500">
                                    Articles
                                </dt>
                                <dt onClick={() => expand(2)} className="text-base font-medium text-gray-900 md:col-span-3 cursor-pointer hover:text-gray-500">
                                    Book Sales
                                </dt>
                                <dt onClick={() => expand(3)} className="text-base font-medium text-gray-900 md:col-span-3 cursor-pointer hover:text-gray-500">
                                    Authoring Help
                                </dt>
                                <dt onClick={() => expand(4)} className="text-base font-medium text-gray-900 md:col-span-3 cursor-pointer hover:text-gray-500">
                                    Art Work & Illustration
                                </dt>
                            </div>
                            <div className="pt-6 pb-8 md:grid md:grid-cols-12 md:gap-8">
                                <dd className={`${tab === 1 ? '' : 'hidden'} mt-2 md:mt-0 md:col-span-12`}>
                                    <div className="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-1 lg:max-w-7xl h-full">
                                        <div className="relative bg-white ">
                                            <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
                                                <div>
                                                    <h2 className="text-base font-semibold tracking-wider text-cyan-600 uppercase">Articles</h2>


                                                </div>
                                                <div className="mt-12">
                                                    <div className="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">

                                                        {Posts}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </dd>
                                <dd className={`${tab === 2 ? '' : 'hidden'} mt-2 md:mt-0 md:col-span-12`}>
                                    <div className="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-1 lg:max-w-7xl h-full">
                                        <div className="relative bg-white ">
                                            <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
                                                <div>
                                                    <h2 className="text-base font-semibold tracking-wider text-cyan-600 uppercase">Publications</h2>

                                                    <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                                                        Tim is an experienced author and editor of technical books and articles. He recently published ‘The Practical Guide to Grade D Fire Alarm Systems'.
                                                        Contact us to see if we can provide technical articles or publications covering all aspects of electrical installation and associated works.
                                                    </p>
                                                </div>
                                                <div className="mt-12">
                                                    <img src="../assets/pubs.png" alt="publications" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </dd>
                                <dd className={`${tab === 3 ? '' : 'hidden'} mt-2 md:mt-0 md:col-span-12`}>
                                    <div className="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-1 lg:max-w-7xl h-full">
                                        <div className="relative bg-white ">
                                            <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
                                                <div>
                                                    <h2 className="text-base font-semibold tracking-wider text-cyan-600 uppercase">Authoring Help</h2>
                                                    <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                                                        In addition to his strong electrical experience, Tim is an accomplished and experienced professional Technical Author and Editor. We are able to provide both technical and editorial support to technical authors. We are also able to support technical authors who wish to publish their work independently
                                                    </p>
                                                </div>
                                                <div className="mt-12">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </dd>
                                <dd className={`${tab === 4 ? '' : 'hidden'} mt-2 md:mt-0 md:col-span-12`}>
                                    <div className="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-1 lg:max-w-7xl h-full">
                                        <div className="relative bg-white ">
                                            <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
                                                <div>
                                                    <h2 className="text-base font-semibold tracking-wider text-cyan-600 uppercase">Art Work & Illustration</h2>


                                                </div>
                                                <div className="mt-12">

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>

            <Logos />
        </Layout>
    );
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
        edges {
            node {
                id
                excerpt(pruneLength: 250)
                frontmatter {
                    date(formatString: "MMMM DD, YYYY")
                    path
                    title
                    thumbnail
                }
            }
        }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      frontmatter {
        title
        metaDescription
        types {
            heading
            description
        }
      }
    }
  }
`;
